import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  MinusIcon,
  ViewIcon,
} from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import {
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import AddUploadedExerciceModal from "pages/NewAppointment/components/SelectExercices/components/AddUploadedExerciceModal"

interface IProps {
  selectedUploadedExercice: SelectedUploadedExerciceType
  setSelectedUploadedExercices: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  setSelectedVideoOrder: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  position: number
  size: number
  isDisabled?: boolean
  showOnlyDuration?: boolean
}

const SelectedUploadedExerciceCard: React.FC<IProps> = ({
  selectedUploadedExercice,
  setSelectedUploadedExercices,
  setSelectedVideoOrder,
  position,
  size,
  isDisabled = false,
  showOnlyDuration = true,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalType, setModalType] = useState<
    "editUploadedExercice" | "showVideo"
  >("showVideo")
  useEffect(() => {
    setSelectedUploadedExercices((prev) => {
      return prev.map((exercice) => {
        if (exercice.id === selectedUploadedExercice.id) {
          return { ...exercice }
        }
        return exercice
      })
    })
  }, [selectedUploadedExercice.id, setSelectedUploadedExercices])

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 5,
      defaultValue: selectedUploadedExercice.duration ?? 30,
      min: 10,
      max: 300,
      isDisabled,
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  const value = input["aria-valuenow"]
  useEffect(() => {
    if (!value) return

    setSelectedUploadedExercices((prev) => {
      return prev.map((exercice) => {
        if (exercice.id === selectedUploadedExercice.id) {
          return { ...exercice, duration: value }
        }
        return exercice
      })
    })
  }, [value, selectedUploadedExercice.id, setSelectedUploadedExercices])

  const handleRemoveExercice = () => {
    if (!isDisabled) {
      setSelectedVideoOrder((prev) => {
        return prev.filter(
          (exercice) =>
            !(
              exercice.id === selectedUploadedExercice.id &&
              exercice.type === "uploaded_video"
            )
        )
      })
      setSelectedUploadedExercices((prev) => {
        return prev.filter(
          (exercice) => exercice.id !== selectedUploadedExercice.id
        )
      })
    }
  }

  const increasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedVideoOrder((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos + 1]
        newExercices[pos + 1] = exercice
        return newExercices
      })
    }
  }

  const decreasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedVideoOrder((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos - 1]
        newExercices[pos - 1] = exercice
        return newExercices
      })
    }
  }

  const [isHoveringPreview, setIsHoveringPreview] = useState(false)
  const [isHoveringAll, setIsHoveringAll] = useState(false)

  return (
    <Flex alignItems="center" as="li" gap={2}>
      <Flex
        flexDir="column"
        alignItems="center"
        bg="white"
        borderRadius={9}
        py={2.5}
        px={1}
      >
        {position !== 1 && (
          <ChevronUpIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={decreasePosition}
          />
        )}
        <Text>{position}</Text>
        {position !== size && (
          <ChevronDownIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={increasePosition}
          />
        )}
      </Flex>
      <Flex
        direction={"column"}
        flex={1}
        bg="white"
        borderRadius={9}
        onClick={(e) => {
          e.stopPropagation()
          if (!showOnlyDuration) {
            setModalType("editUploadedExercice")
            onOpen()
          }
        }}
        cursor={
          isDisabled ? "not-allowed" : showOnlyDuration ? "normal" : "pointer"
        }
      >
        <Flex
          py={2}
          px={3}
          pos="relative"
          flex={1}
          alignItems="center"
          gap="10px"
          onMouseEnter={() => setIsHoveringAll(true)}
          onMouseLeave={() => setIsHoveringAll(false)}
        >
          <Box
            pos="relative"
            onMouseEnter={() => setIsHoveringPreview(true)}
            onMouseLeave={() => setIsHoveringPreview(false)}
            rounded="8px"
            w="64px"
            h="64px"
            overflow="hidden"
          >
            <Image
              alt={selectedUploadedExercice.title}
              src={selectedUploadedExercice.bunnyCDN.preview}
              w="64px"
              h="64px"
              objectFit="cover"
            />
            {isHoveringPreview && !isDisabled && (
              <Flex
                bg="rgba(17, 23, 45, 0.5)"
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Flex
                  cursor={isDisabled ? "not-allowed" : "pointer"}
                  borderRadius="50%"
                  border="1px solid"
                  borderColor="primary.500"
                  w={34}
                  h={34}
                  justifyContent="center"
                  alignItems="center"
                  onClick={(e) => {
                    e.stopPropagation()
                    setModalType("showVideo")
                    onOpen()
                  }}
                  color="primary.500"
                  bg="white"
                  _hover={{
                    bg: "primary.100",
                  }}
                >
                  <ViewIcon />
                </Flex>
              </Flex>
            )}
          </Box>
          <Flex flexDirection="column" w="full" flex={1} align={"flex-start"}>
            <Text color="tertiary.500" fontSize={14} fontWeight={700}>
              {selectedUploadedExercice.title}
            </Text>
            <Text
              fontSize={12}
            >{`Niveau ${selectedUploadedExercice.level}`}</Text>
            {showOnlyDuration && (
              <Flex alignItems="end" w="full" justifyContent="end">
                <Flex alignItems="center">
                  <MinusIcon
                    {...dec}
                    w={3}
                    color="primary.500"
                    cursor={isDisabled ? "not-allowed" : "pointer"}
                  >
                    -
                  </MinusIcon>
                  <Input
                    {...input}
                    bg="common.100"
                    w="60px"
                    h="25px"
                    p={2}
                    textAlign="center"
                    mx={2}
                    fontWeight={700}
                    fontSize={14}
                    cursor={isDisabled ? "not-allowed" : "auto"}
                  />
                  <AddIcon
                    {...inc}
                    w={3}
                    color="primary.500"
                    cursor={isDisabled ? "not-allowed" : "pointer"}
                  >
                    -
                  </AddIcon>
                </Flex>
              </Flex>
            )}
          </Flex>
          {isHoveringAll && (
            <Flex pos="absolute" top={0} right={0} alignItems="center">
              <IconButton
                cursor={isDisabled ? "not-allowed" : "pointer"}
                onClick={handleRemoveExercice}
                _hover={{ color: "danger.500" }}
                aria-label="Supprimer la vidéo"
                background="none"
                icon={<DeleteIcon boxSize={3.5} />}
                color="common.400"
              />
            </Flex>
          )}
        </Flex>

        {showOnlyDuration ? null : (
          <Flex
            justify={"space-between"}
            borderRadius={"4px"}
            bg={"common.100"}
            flex={1}
            my={2}
            mx={3}
            fontSize={12}
            px={2}
          >
            <Flex gap={1}>
              <Text fontWeight="bold">{selectedUploadedExercice.series}</Text>
              <Text>séries</Text>
            </Flex>
            {selectedUploadedExercice.type?.key === "repetition" && (
              <Flex gap={1}>
                <Text fontWeight="bold">
                  {selectedUploadedExercice.repetitions}
                </Text>
                <Text>répétitions</Text>
              </Flex>
            )}
            {selectedUploadedExercice.type?.key === "time" && (
              <Flex gap={1}>
                <Text fontWeight="bold">
                  {selectedUploadedExercice.duration}
                </Text>
                <Text>temps (sec)</Text>
              </Flex>
            )}
            {selectedUploadedExercice.type?.key === "untilFailure" && (
              <Flex gap={1}>
                <Text fontWeight="bold">jusqu'à l'échec</Text>
              </Flex>
            )}
            <Flex gap={1}>
              <Text fontWeight="bold">
                {selectedUploadedExercice.restDuration}
              </Text>
              <Text>repos (sec)</Text>
            </Flex>
          </Flex>
        )}
      </Flex>
      {isOpen && modalType === "showVideo" && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay bg="blackAlpha.400" />
          <ModalContent maxW="724">
            <Box
              pos="relative"
              borderRadius={9}
              bg="white"
              w={724}
              h={469}
              py="32px"
              px="54px"
            >
              <ModalCloseButton aria-label="Fermer" />
              <Heading
                fontSize={22}
                fontWeight="bold"
                textAlign="center"
                mb={7}
              >
                {selectedUploadedExercice.title}
              </Heading>
              {/* <video
                src={selectedUploadedExercice.bunnyCDN.video}
                controls
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
              ></video> */}
              <Box pos="relative" pt="56.25%">
                <iframe
                  src={`${selectedUploadedExercice.bunnyCDN.video}?autoplay=true&loop=false&muted=true&preload=true&responsive=true`}
                  loading="lazy"
                  style={{
                    border: 0,
                    position: "absolute",
                    top: 0,
                    height: "100%",
                    width: "100%",
                  }}
                  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                  allowFullScreen
                  title={selectedUploadedExercice.title}
                />
              </Box>
            </Box>
          </ModalContent>
        </Modal>
      )}
      {isOpen && modalType === "editUploadedExercice" && (
        <AddUploadedExerciceModal
          isOpen={isOpen}
          onClose={onClose}
          video={selectedUploadedExercice}
          setSelectedExercices={setSelectedUploadedExercices}
          setSelectedVideoOrder={setSelectedVideoOrder}
          isEditExercice={true}
        />
      )}
    </Flex>
  )
}

export default SelectedUploadedExerciceCard
