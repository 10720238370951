import React from "react"
import { SVGProps } from "../type"

const Abdominaux: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      width="40"
      height="93"
      viewBox="0 0 40 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.16348 19.6372L13.0752 1.45206C13.26 1.16996 13.5745 1 13.9117 1H26.4773C26.8198 1 27.1385 1.17531 27.3219 1.4646L38.8446 19.6416C38.9461 19.8018 39 19.9875 39 20.177V42.2076V59.2291C39 59.3274 38.9855 59.4252 38.957 59.5192L32.0603 82.2674C32.0031 82.456 31.8915 82.6235 31.7395 82.7488L21.1291 91.4982C20.7722 91.7924 20.2601 91.8036 19.8908 91.525L8.26073 82.7533C8.09131 82.6255 7.96694 82.4471 7.90559 82.244L1.0427 59.5188C1.01439 59.425 1 59.3276 1 59.2297V20.1852C1 19.9905 1.05682 19.8001 1.16348 19.6372Z"
        fill={fill || "#E4FBEA"}
        fillOpacity={"0.8"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="2"
      />
    </svg>
  )
}

export default Abdominaux
