import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { TreatmentType } from "api/treatment"
import ANewButton from "components/ANewButton"
import AllAppointmentModal from "components/Treatment/AllAppointmentModal"
import { format } from "date-fns"
import getUtcDate from "utils/getUtcDate"
import { AddIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"

interface TreatmentCardProps {
  treatment: TreatmentType
  isSelected: boolean
}

const TreatmentCard = ({ treatment, isSelected }: TreatmentCardProps) => {
  const sortedAppointments = treatment.appointments.sort((a, b) => b.id - a.id)
  const navigate = useNavigate()

  const {
    isOpen: isAllAppointmentsModalOpen,
    onOpen: onOpenAllAppointmentsModal,
    onClose: onCloseAllAppointmentsModal,
  } = useDisclosure()

  const displayedAppointments = sortedAppointments.slice(0, 3)
  const hasMoreAppointments = sortedAppointments.length > 3

  const handleSelectTreatment = () => {
    navigate(
      `/patients/${treatment.patient.id}/treatment/${treatment.id}/appointment/${treatment.appointments[0].id}`
    )
  }

  const isJobKineOrPodo = useJobIsKineOrPodo()

  return (
    <Flex
      borderRadius={9}
      p={4}
      border={isSelected ? "2px solid" : "none"}
      borderColor={"valid.300"}
      bg={isSelected ? "secondary.100" : "white"}
      direction={"column"}
      cursor={isSelected ? "default" : "pointer"}
      onClick={handleSelectTreatment}
    >
      <Flex justify={"space-between"}>
        <Text fontSize={16} fontWeight={"bold"}>
          {treatment.title}
        </Text>
      </Flex>
      <Flex direction={"column"} mt={4} gap={"8px"}>
        {displayedAppointments.map((appointment) => (
          <Text fontWeight={"bold"} key={appointment.id}>
            {format(getUtcDate(appointment.meetingDate || ""), "dd/MM/yyyy")}
          </Text>
        ))}
        {hasMoreAppointments && isSelected && (
          <Text
            onClick={onOpenAllAppointmentsModal}
            textDecoration={"underline"}
            cursor={"pointer"}
          >
            Voir plus
          </Text>
        )}
        {isSelected && treatment.id !== -1 && isJobKineOrPodo && (
          <ANewButton
            text="Nouvelle consultation"
            variant="teleconsultation"
            icon={<AddIcon fontSize={"12px"} />}
            onClick={(e) => {
              e.stopPropagation()
              navigate(
                `/patients/${treatment.patient.id}/appointments/new/treament/${treatment.id}`
              )
            }}
          />
        )}
      </Flex>
      {isAllAppointmentsModalOpen && (
        <AllAppointmentModal
          isOpen={isAllAppointmentsModalOpen}
          onClose={onCloseAllAppointmentsModal}
          appointments={treatment.appointments}
          treatmentTitle={treatment.title}
        />
      )}
    </Flex>
  )
}

export default TreatmentCard
