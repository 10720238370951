import { JobType } from "api/job"
import { MediaType } from "api/media"
import { TrainingVideoExerciceType } from "api/trainingVideoExerciceType"
import axiosInstance from "./axiosInstance"

export type VideoPreviewType = {
  id: number
  alternativeText: string
  formats: {
    small: {
      url: string
    }
  }
}

export type VideoContentType = {
  id: number
  url: string
  caption: string
}

export type VideoBunnyCDNType = {
  fullVideo: string
  loopVideo: string
  preview: string
  previewWebP: string
  video: string
}

export type VideoType = {
  id: number
  level: number
  limitedMode: boolean
  title: string
  preview?: VideoPreviewType
  fullVideo?: VideoContentType
  defaultDuration?: number
  minimumDuration?: number
  maximumDuration?: number
  group: TrainingVideoGroupsLightType[]
  videoGroup: number
  bunnyCDN?: VideoBunnyCDNType
  isAsymmetrical?: boolean
  config?: TrainingVideoConfig
}

export type TrainingVideoUploadedType = {
  id: number
  title: string
  level: number
  config: TrainingVideoConfig
  bunnyCDN: {
    preview: string
    video: string
    webp: string
    fullVideo: string
  }
}

// export type SelectedExerciceType = VideoType & {
//   duration?: number
//   series?: number
//   repetitions?: number
//   restDuration?: number
//   type?: TrainingVideoExerciceType
//   side?: "both" | "left" | "right"
//   weight?: number
// }
export type SelectedExerciceType = VideoType & {
  duration: number | null
  series: number | null
  repetitions: number | null
  restDuration: number | null
  type: TrainingVideoExerciceType | null
  side: "both" | "left" | "right" | null
  canUseWeight?: boolean
  weight: number | null
  recommendation: string | null
}

export type SelectedUploadedExerciceType = TrainingVideoUploadedType & {
  duration: number | null
  series: number | null
  repetitions: number | null
  restDuration: number | null
  type: TrainingVideoExerciceType | null
  canUseWeight?: boolean
  weight: number | null
  recommendation: string | null
}

export type SelectedVideoOrderType = {
  id: number
  type: "uploaded_video" | "video"
}

// export type SelectedDiscoverType = MediaType
export type SelectedDiscoverType = Pick<
  MediaType,
  "id" | "title" | "type" | "media"
>

export type VideoGroupCategoryType = "souplesse" | "renfo" | "mobilite" | "nerf"
export type VideoGroupRestriction = {
  id: number
  name: string
}

export type TrainingVideoGroupsLightType = {
  id: number
  title: string
}

export type TrainingVideoGroupsType = TrainingVideoGroupsLightType & {
  videos: SelectedExerciceType[]
  category: VideoGroupCategoryType
  restrictions: VideoGroupRestriction[]
  timeUsed: number
  osteosLiking?: number[]
  clinicalPictures?: ClinicalPictureType[]
  jobs: JobType[]
}

export type ClinicalPictureType = {
  id: number
  name: string
  //trainingVideoGroups: TrainingVideoGroupsLightType[]
  trainingVideoGroups: TrainingVideoGroupsType[]
}

export type TrainingVideoConfig = {
  types: string[]
  duration: number
  restDuration: number
  series: number
  repetitions: number
  canUseWeight: boolean
  weightInKg: number
  description: string
}

export type AppointmentReasonType = {
  id: number
  internalId: number
  name: string
  trainingVideoGroups: TrainingVideoGroupsType[]
  suggestedProgramme: VideoType[]
  clinicalPictures: ClinicalPictureType[]
}

export type PaginateType = {
  page: number
  total: number
  pageSize: number
  pageCount: number
}

export type AppointmentReasonTypePaginated = AppointmentReasonType & {
  pagination: PaginateType
}

export interface GetAppointmentReasonParams {
  page: number
  reasonId?: number
  search?: string
}

export const getAppointmentReason = async ({
  page,
  reasonId,
  search,
}: GetAppointmentReasonParams) => {
  const response = await axiosInstance.post<AppointmentReasonTypePaginated>(
    `/osteo/me/appointmentReason/${reasonId}`,
    { page, search }
  )
  return response
}

export const getUploadedVideosForAppointmentReason = async (
  reasonId: number
) => {
  const response = await axiosInstance.get<SelectedUploadedExerciceType[]>(
    `/osteo/me/appointmentReason/${reasonId}/uploaded-videos`
  )
  return response.data
}

export const getLikedVideosForAppointmentReason = async (reasonId: number) => {
  const response = await axiosInstance.get<TrainingVideoGroupsType[]>(
    `/osteo/me/appointmentReason/${reasonId}/liked-videos`
  )
  return response.data
}

export const getAppointmentReasons = async () => {
  const response = await axiosInstance.get<AppointmentReasonType[]>(
    `/osteo/me/appointmentReasons`
  )
  return response.data
}
