import { TeleconsultationType } from "api/teleconsultation"
import { Box, Flex, Input, Text, Textarea } from "@chakra-ui/react"
import { format } from "date-fns"
import getUtcDate from "utils/getUtcDate"
import ANewButton from "components/ANewButton"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAppContext } from "AppContext"

interface TeleconsultationScreenProps {
  teleconsultation: TeleconsultationType
  privateNote: string
  setPrivateNote: (privateNote: string) => void
  recalculedPrice: number
  setRecalculedPrice: (recalculatedPrice: number) => void
}
const TeleconsultationScreen = ({
  teleconsultation,
  privateNote,
  recalculedPrice,
  setRecalculedPrice,
  setPrivateNote,
}: TeleconsultationScreenProps) => {
  const { user } = useAppContext()
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (teleconsultation && !scriptLoaded) {
      // Charger le script <script src="%PUBLIC_URL%/linkello/linkello.toolkit-1.4.1.js"></script> ici
      const script = document.createElement("script")
      script.src = "/linkello/linkello.loader-1.0.0.js"
      document.body.appendChild(script)
      setScriptLoaded(true)
    }
  }, [teleconsultation, scriptLoaded])

  return (
    <Flex bg={"white"} p={4} borderRadius={"10px"} gap={4}>
      <Flex direction={"column"} justify={"space-between"}>
        <Flex direction={"column"} gap={4} align={"start"}>
          <Flex direction={"column"} gap={2}>
            <Text fontWeight={"bold"} fontSize={"18px"}>
              {teleconsultation.patient.displayname}
            </Text>
            <Text>{teleconsultation.patient.account?.email}</Text>
            <Text>{teleconsultation.patient.telephone}</Text>
            <Text>
              {format(
                getUtcDate(teleconsultation.patient.birthday || ""),
                "dd/MM/yyyy"
              )}
            </Text>
          </Flex>
          <Link to={`/patients/${teleconsultation.patient.id}`} target="_blank">
            <ANewButton
              text={"Voir la page patient"}
              variant={"teleconsultation"}
            />
          </Link>
          <Flex justify={"space-between"} align={"center"} w={"full"}>
            <Text w={"50%"}>Montant</Text>
            <Flex align={"center"} justify={"justify-end"} w={"50%"} gap={4}>
              <Input
                value={recalculedPrice}
                name="price"
                onChange={(e: any) => setRecalculedPrice(e.target.value)}
                bgColor="common.100"
                border={"none"}
                w={"70px"}
                color={"black"}
                fontWeight={"bold"}
                disabled={!user?.osteo.stripeConnectAccount}
              />
              <Text fontWeight={"bold"} color={"common.500"}>
                euros
              </Text>
            </Flex>
          </Flex>
          {!user?.osteo.stripeConnectAccount && (
            <Text fontSize={12} color="common.500" mt={0}>
              Vous devez d'abord créer un compte Stripe Connect pour pouvoir
              facturer une téléconsultation.
            </Text>
          )}
        </Flex>
        <Flex direction={"column"} gap={4}>
          <Text fontWeight={"bold"} fontSize={"14px"}>
            Vos notes privées
          </Text>
          <Textarea
            placeholder="Note complémentaire..."
            value={privateNote || ""}
            bgColor="#FEEFC5"
            border="1px solid"
            borderColor="#F9D690"
            minHeight="152px"
            onChange={(e: any) => setPrivateNote(e.target.value)}
          />
        </Flex>
      </Flex>

      <Box
        w={"calc(100vw - 400px)"}
        h={"calc(100vh - 150px)"}
        id="root"
        data-name={`${user?.osteo?.firstname} ${user?.osteo?.lastname}`}
        data-app={process.env.REACT_APP_LINKELLO_APP_ID}
        data-key={teleconsultation.linkelloKey}
        data-version="1.4.1"
        data-language="fr"
        data-signature={teleconsultation.linkelloSignature}
        data-exit-url="/"
        data-chat="true"
        data-domain="api.linkello.com"
        // data-rating-on="false"
        data-logo-url="/andrew.png"
        // data-background-image-url="/andrew.png"
        // data-background-image-repeat="false"
        // data-sound-url="https://.../sound.mp3"
        // data-extra-css="https://.../css/custom.css"
      ></Box>
    </Flex>
  )
}

export default TeleconsultationScreen
