import { Box, Heading, useDisclosure } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import AWelcomeModal from "components/AWelcomeModal"
import useStoreSuccessPaymentSessionIndividual from "hooks/useStoreSuccessPaymentSession/individual"
import ACooptionModal from "components/ACooptionModal"
import ANewFeaturesModal from "../../components/ANewFeaturesModal"
import useNewFeaturesModalDisclosure from "hooks/useNewFeaturesModalDisclosure"
import useCooptionModalDisclosure from "hooks/useCooptionModalDisclosure"
import { getOneModal } from "api/modal"
import { getAllStats } from "api/stats"
import Statistics from "components/Stats/Statistics"
import { useAppContext } from "AppContext"
import { JobType } from "api/job"

const Dashboard = () => {
  const { data: stats } = useQuery(["getAllStats"], getAllStats)

  const { sessionId, clearSessionId } =
    useStoreSuccessPaymentSessionIndividual()

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: Boolean(sessionId),
  })

  const { isOpen: isCooptionModalOpen, onClose: onCooptionModalClose } =
    useCooptionModalDisclosure(isOpen)

  const newFeaturesModalQuery = useQuery(["getOneModal", 1], () =>
    getOneModal(1)
  )

  const newFeaturesModalName = newFeaturesModalQuery.isSuccess
    ? newFeaturesModalQuery.data.name
    : undefined
  const newFeaturesModalIsDiplayed = newFeaturesModalQuery.isSuccess
    ? newFeaturesModalQuery.data.isDisplayed
    : false

  const newFeaturesModalJobs = newFeaturesModalQuery.isSuccess
    ? newFeaturesModalQuery.data.jobs
    : undefined

  const { user } = useAppContext()

  const userJobs = user?.osteo.jobs

  const userJobsIds = userJobs?.map((job) => job.id)
  const newFeaturesModalJobsIds = newFeaturesModalJobs?.map(
    (job: JobType) => job.id
  )
  const hasUserJob = userJobsIds?.some((jobId) =>
    newFeaturesModalJobsIds?.includes(jobId)
  )

  const { isOpen: isNewFeaturesModalOpen, onClose: onNewFeaturesModalClose } =
    useNewFeaturesModalDisclosure(
      newFeaturesModalName,
      newFeaturesModalIsDiplayed,
      isOpen,
      hasUserJob
    )

  const onWelcomeCloseRemoveSessionId = () => {
    clearSessionId()
    onClose()
  }

  return (
    <Box>
      <Statistics stats={stats} />
      <AWelcomeModal isOpen={isOpen} onClose={onWelcomeCloseRemoveSessionId} />
      <ACooptionModal
        isOpen={isCooptionModalOpen}
        onClose={onCooptionModalClose}
      />
      <ANewFeaturesModal
        isOpen={isNewFeaturesModalOpen}
        onClose={onNewFeaturesModalClose}
        queryData={{
          data: newFeaturesModalQuery.data,
          isLoading: newFeaturesModalQuery.isLoading,
          isError: newFeaturesModalQuery.isError,
        }}
      />
    </Box>
  )
}

export default Dashboard
